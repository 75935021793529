import { AppModule } from 'app/module';
import { mediaQueries } from 'util/mediaQueries';
import './styles.scss';
import Template from './template.hbs';

export class StaticMap extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    domBindings() {
        return {
            inner: '.m-staticMap__inner',
            pictureElement: '.m-staticMap__pictureElement',
            image: '.m-staticMap__image',
            mobileSrc: '.m-staticMap__mobileSrc',
            tabletSrc: '.m-staticMap__tabletSrc',
            bgImage: '.m-staticMap__bgImage',
            copyright: '.m-staticMap__copyright',
        };
    }

    getPropsFromDom() {
        return {
            active: !!this.dom.inner,
            lateLoad: this.dom.el.getAttribute('data-is-late-load') === 'true',
            copyright: this.dom.copyright ? this.dom.copyright.innerText : '',
            tablet: this.dom.inner ? this.dom.inner.getAttribute('data-tablet-src') : '',
            mobile: this.dom.inner ? this.dom.inner.getAttribute('data-mobile-src') : '',
            isBrandingSolution: this.dom.inner ? this.dom.inner.classList.contains('m-staticMap__inner--bs') : false,
        };
    }

    ready() {
        if (this.props.lateLoad || !mediaQueries.isDesktop()) {
            this.addViewportObserver();
        } else {
            app.events.on('firstUserInteraction', () => this.onFirstUserInterAction());
        }
    }

    entersViewport() {
        this.lateLoadStaticMap();
    }

    onFirstUserInterAction() {
        this.lateLoadStaticMap();
    }

    lateLoadStaticMap() {
        if (this.dom.pictureElement) {
            this.dom.image.setAttribute('src', this.dom.image.getAttribute('data-latesrc'));
            this.dom.image.removeAttribute('data-latesrc');

            this.dom.mobileSrc.setAttribute('srcset', this.dom.mobileSrc.getAttribute('data-srcset'));
            this.dom.mobileSrc.removeAttribute('data-srcset');

            this.dom.tabletSrc.setAttribute('srcset', this.dom.tabletSrc.getAttribute('data-srcset'));
            this.dom.tabletSrc.removeAttribute('data-srcset');

            if (this.props.isBrandingSolution) {
                this.dom.inner.classList.remove('m-staticMap__inner--placeholderBs');
            } else {
                this.dom.inner.classList.remove('m-staticMap__inner--placeholder');
            }
        }
    }
}

import { AppModule } from 'app/module';
import { getJson } from 'util/xhr';
import Template from './template.hbs';
import './styles.scss';

export class CompanyText extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    domBindings() {
        return {
            toggleShowMore: '.m-companyText__toggleShowMore',
            shortText: '.m-companyText__shortText',
            detailText: '.m-companyText__detailText',
        };
    }

    getPropsFromDom() {
        return {
            shortText: this.dom.shortText ? this.dom.shortText.innerHTML : '',
            active: this.dom.shortText !== null,
            detailUrl: this.dom.el.getAttribute('data-detail-url'),
            detailText: this.dom.detailText ? this.dom.detailText.innerHTML : '',
            showButton: this.dom.toggleShowMore !== null,
            buttonPosition: this.dom.buttonContainer
                ? this.dom.buttonContainer.getAttribute('data-buttonPosition') : false,
            isTypeBrandingSolution: this.dom.el
                ? this.dom.el.classList.contains('m-companyText--bs') : false,
            theme: this.dom.el.classList.contains('m-companyText__agp'),
        };
    }

    domEvents() {
        if (this.dom.toggleShowMore) {
            this.dom.toggleShowMore.addEventListener('click', () => this.toggleMore());
        }
    }

    afterRender() {
        if (window.self !== window.parent) {
            this.events.emit('updateIFrame');
        }
    }

    toggleMore() {
        if (this.props.showMore) {
            this.events.emit('scrollToSection');
        }
        if (this.props.detailText === '') {
            this.fetch = getJson(
                this.props.detailUrl,
                {},
                (response) => this.onResponse(response),
                () => this.onError(),
            );
        } else {
            this.updateProps({ showMore: !this.props.showMore, theme: 'agp' }, true);
        }
    }

    onResponse(response) {
        this.fetch = false;
        this.updateProps({
            detailText: response.companyTextDetail,
            showMore: !this.props.showMore,
            theme: 'agp',
        }, true);
    }

    onError() {
        this.fetch = false;
        this.updateProps({ error: true }, true);
    }
}

import { AppModule } from 'app/module';
import { FollowButton } from 'module/followButton/main';
import { CompanyFollowLoginInfo } from 'module/companyFollowLoginInfo/main';
import Template from './template.hbs';
import './styles.scss';

export class CompanyHeader extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    domBindings() {
        return {
            followButton: '.m-companyHeader__follow .m-followButton',
            companyFollowLoginInfo: '.m-companyFollowLoginInfo',
            inner: '.m-companyHeader__inner',
            titleDetails: '.m-companyHeader__details',
        };
    }

    getPropsFromDom() {
        return {
            id: this.dom.inner ? this.dom.inner.getAttribute('data-id') : null,
        };
    }

    domEvents() {
        if (this.dom.closeTrigger) {
            this.dom.closeTrigger.addEventListener('click', () => this.closeModalViaClick());
        }
    }

    subs() {
        if (this.dom.followButton !== null) { // if inactive jobs ...
            this.followButton = new FollowButton(this.dom.followButton);
        }

        if (this.dom.companyFollowLoginInfo) {
            this.companyFollowLoginInfo = new CompanyFollowLoginInfo(this.dom.companyFollowLoginInfo);
        }
    }

    subEvents() {
        if (this.companyFollowLoginInfo) {
            this.companyFollowLoginInfo.events.on('onCloseButtonClicked', () => this.closeCompanyFollowLoginInfo());
        }
    }

    subsProps() {
        if (this.followButton) {
            this.followButton.updateProps({ id: this.props.id });
        }

        if (this.companyFollowLoginInfo) {
            this.companyFollowLoginInfo.updateProps({ id: this.props.id });
        }
    }

    closeCompanyFollowLoginInfo() {
        if (this.companyFollowLoginInfo) {
            this.companyFollowLoginInfo.updateProps({ visible: false }, true);
        }
    }
}

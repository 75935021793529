import { app } from 'app/app';
import { user } from 'app/user';
import { userWatchlist } from 'app/userWatchlist';
import { userCompanyFollow } from 'app/userCompanyFollow';
import { AppController } from 'app/controller';
import { MasterLayout } from 'layout/master/main';
import { CompanyHeader } from 'module/companyHeader/main';
import { CompanyVideo } from 'module/companyVideo/main';
import { SubNavigation } from 'module/subNavigation/main';
import { CompanyEventList } from 'module/companyEventList/main';
import { CompanyLocationMap } from 'module/companyLocationMap/main';
import { JobCardList } from 'module/jobCardList/main';
import { Section } from 'module/section/main';
import { TIME_SNACKBAR_MEDIUM } from 'app/timings';
import { MAIL_NEVER, MAIL_WEEKLY } from 'app/mailSubscriptionInterval';
import { CompanyMediaItems } from 'module/companyMediaItems/main';
import { CompanyText } from 'module/companyText/main';
import { trackGa4, sendPageView } from 'util/googleAnalytics';
import { log } from '@karriereat/action-logger/dist/log';
import 'module/bsCompanyLinks/main';
import 'module/companyFactsBox/main';
import 'module/companyAwards/main';
import './styles.scss';

class BrandingSolutionIndex extends AppController {
    domBindings() {
        return {
            brandingSolution: '.c-brandingSolutionIndex',
            companyHeader: '.m-companyHeader',
            subNavigation: '.m-subNavigation',
            whatWeDoText: '.c-brandingSolutionIndex__whatWeDo .m-companyText',
            whatWeDoSection: '.c-brandingSolutionIndex__whatWeDo .m-section',
            aboutUsText: '.c-brandingSolutionIndex__aboutUs .m-companyText',
            aboutUsTextSection: '.c-brandingSolutionIndex__aboutUs .m-section',
            companyProspectText: '.c-brandingSolutionIndex__companyProspects .m-companyText',
            companyProspectSection: '.c-brandingSolutionIndex__companyProspects .m-section',
            tourVideo: '.c-brandingSolutionIndex__tourVideo .m-companyVideo',
            tourVideoSection: '.c-brandingSolutionIndex__tourVideo .m-section',
            employerDecisionVideo: '.c-brandingSolutionIndex__employerDecisionVideo .m-companyVideo',
            employerDecisionVideoSection: '.c-brandingSolutionIndex__employerDecisionVideo .m-section',
            companyEventList: '.m-companyEventList',
            jobCardList: '.m-jobCardList',
            companyLocationMap: '.m-companyLocationMap',
            companyMediaItems: '.m-companyMediaItems',
            companyMediaItemsSection: '.c-brandingSolutionIndex__companyMediaItems .m-section',
        };
    }

    getPropsFromDom() {
        return {
            slug: this.dom.brandingSolution.getAttribute('data-slug'),
            companyAlarmNotificationInterval:
                parseInt(this.dom.brandingSolution.getAttribute('data-companyalarmnotificationinterval'), 10),
            companyId: this.dom.brandingSolution.getAttribute('data-company-id'),
        };
    }

    subs() {
        this.layout = new MasterLayout(document.documentElement);
        this.companyHeader = new CompanyHeader(this.dom.companyHeader);

        if (this.dom.subNavigation) {
            this.subNavigation = new SubNavigation(this.dom.subNavigation);
        }
        if (this.dom.aboutUsText) {
            this.aboutUsText = new CompanyText(this.dom.aboutUsText);
        }
        if (this.dom.whatWeDoText) {
            this.whatWeDoText = new CompanyText(this.dom.whatWeDoText);
        }
        if (this.dom.whatWeDoSection) {
            this.whatWeDoSection = new Section(this.dom.whatWeDoSection, [this.whatWeDoText]);
        }
        if (this.dom.companyProspectText) {
            this.companyProspectText = new CompanyText(this.dom.companyProspectText);
        }
        if (this.dom.companyProspectSection) {
            this.companyProspectSection = new Section(this.dom.companyProspectSection, [this.companyProspectText]);
        }
        if (this.dom.aboutUsTextSection) {
            this.aboutUsTextSection = new Section(this.dom.aboutUsTextSection, [this.aboutUsText]);
        }
        if (this.dom.companyEventList) {
            this.companyEventList = new CompanyEventList(this.dom.companyEventList);
        }
        if (this.dom.jobCardList) {
            this.jobCardList = new JobCardList(this.dom.jobCardList);
        }
        if (this.dom.companyMediaItems) {
            this.companyMediaItems = new CompanyMediaItems(this.dom.companyMediaItems);
        }
        if (this.dom.companyLocationMap) {
            this.companyLocationMap = new CompanyLocationMap(this.dom.companyLocationMap);
        }
    }

    ready() {
        app.events.on('firstUserInteraction', () => this.onFirstUserInteraction());
        if (this.companyMediaItems) {
            this.layout.lightBox.updateProps({ mediaItems: this.companyMediaItems.props.mediaItems });
        }

        trackGa4({
            event: 'company_view',
            view_type: 'Standalone',
            product_type: 'branding.solution',
            company_id: this.props.companyId ?? null,
        });
    }

    onPopState(state) {
        if (!state) return; // hashbang fix
        if (this.layout.lightBox.props.active !== false) {
            this.layout.lightBox.closeLightBox();
        } else {
            this.layout.lightBox.openMediaItem(this.layout.lightBox.props.lastSrc);
        }
        sendPageView();
    }

    initializeVideos() {
        if (this.dom.tourVideo) {
            this.tourVideo = new CompanyVideo(this.dom.tourVideo);

            this.tourVideo.events.on('play', () => {
                log('company-video-play', {
                    slug: this.props.slug,
                    type: 'tour',
                });
            });
        }
        if (this.dom.tourVideoSection) {
            this.tourVideoSection = new Section(this.dom.tourVideoSection, [this.tourVideo]);
        }
        if (this.dom.employerDecisionVideo) {
            this.employerDecisionVideo = new CompanyVideo(this.dom.employerDecisionVideo);

            this.employerDecisionVideo.events.on('play', () => {
                log('company-video-play', {
                    slug: this.props.slug,
                    type: 'employer-decision',
                });
            });
        }

        if (this.dom.employerDecisionVideoSection) {
            this.employerDecisionVideoSection = new Section(
                this.dom.employerDecisionVideoSection,
                [this.employerDecisionVideo],
            );
        }
    }

    subEvents() {
        this.initializeVideos();

        if (this.companyMediaItems) {
            this.companyMediaItems.events.on('openMediaItem', (context) => {
                this.layout.lightBox.openMediaItemViaClick(context.arguments[0]);
            });
            this.companyMediaItems.events.on('changedFilter', () => {
                this.layout.lightBox.updateProps({ mediaItems: this.companyMediaItems.props.mediaItems });
            });

            this.layout.lightBox.events.on('video-play', () => {
                log('company-video-play', {
                    slug: this.props.slug,
                    type: 'custom',
                });
            });

            this.layout.lightBox.events.on('image-view', () => {
                log('company-image-view', {
                    slug: this.props.slug,
                });
            });

            this.layout.events.on('pushState', () => {
                this.pushState(this.getProps(), document.title, window.location.href, false);
            });
        }
        if (!user.isLoggedIn()) {
            userWatchlist.events.on('onUnauthorized', (watchlistObject) => {
                this.layout.openLoginModal(watchlistObject.arguments[0].watchlistObject.state, true);
            });
            userCompanyFollow.events.on('onUnauthorized', (followObject) => {
                this.layout.openLoginModal(followObject.arguments[0].companyFollowObject.state, true);
            });
        } else {
            userCompanyFollow.events.on('onAddJob', () => {
                let snackbarText;
                if (this.props.companyAlarmNotificationInterval === MAIL_WEEKLY) {
                    snackbarText = 'Ab sofort erhältst du wöchentlich neue Jobs dieser Firma per E-Mail.';
                } else {
                    snackbarText = 'Ab sofort erhältst du täglich neue Jobs dieser Firma per E-Mail.';
                }

                this.layout.showSnackbar(
                    TIME_SNACKBAR_MEDIUM,
                    false,
                    false,
                    false,
                    false,
                    snackbarText,
                    false,
                    '/settings/notifications#c-notificationIndex-companyalarm',
                    false,
                    'Anpassen',
                );
            });
            userCompanyFollow.events.on('onRemoveJob', () => {
                if (this.props.companyAlarmNotificationInterval !== MAIL_NEVER) {
                    this.layout.showSnackbar(
                        TIME_SNACKBAR_MEDIUM,
                        false,
                        false,
                        false,
                        false,
                        'Du erhältst ab sofort keine Jobs dieser Firma mehr per E-Mail.',
                        true,
                        false,
                        false,
                        false,
                    );
                }
            });
            userCompanyFollow.events.on('onFollowError', (error) => {
                [].forEach.call(error.arguments[0].errors, (err) => {
                    if (err.meta.company_follow_max_limit) {
                        this.layout.showSnackbar(
                            TIME_SNACKBAR_MEDIUM,
                            false,
                            false,
                            false,
                            false,
                            'Oje, du hast das Maximum schon erreicht! Um dieser Firma zu folgen, '
                            + 'entfolge einer anderen Firma.',
                            false,
                            '/companyalarm?openAccordion=true',
                            false,
                            'Zum Firmen-Alarm ',
                        );
                    }
                });
            });
        }
    }

    onFirstUserInteraction() {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        this.initHistoryApi();
    }
}

app.boot(BrandingSolutionIndex);

if (module.hot) {
    module.hot.accept(module.children, () => {
        // eslint-disable-next-line no-console
        console.log('Rebooting controller due to HMR...');
        app.boot(BrandingSolutionIndex);
    });

    module.hot.accept(() => {});
}

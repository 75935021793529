import { AppModule } from 'app/module';
import { userCompanyFollow } from 'app/userCompanyFollow';
import Template from './template.hbs';
import './styles.scss';

export class CompanyFollowLoginInfo extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    getPropsFromDom() {
        return {
            direction: this.dom.el.getAttribute('data-direction'),
            isNavItem: this.dom.el.getAttribute('data-navitem'),
        };
    }

    domBindings() {
        this.dom.closeButton = this.dom.el.querySelector('.m-companyFollowLoginInfo__close');
    }

    domEvents() {
        if (this.dom.closeButton) {
            this.dom.closeButton.addEventListener('click', (e) => this.closeButtonClicked(e));
        }
        userCompanyFollow.events.on(
            'onUnauthorized',
            (followCompanyObject) => this.openFollowCompanyInfo(followCompanyObject),
        );
    }

    closeButtonClicked(e) {
        if (e) e.preventDefault();
        this.events.emit('onCloseButtonClicked');
    }

    openFollowCompanyInfo(followCompanyObject) {
        if (this.props.id === followCompanyObject.arguments[0].companyFollowObject.id) {
            this.updateProps({
                visible: true,
                state: followCompanyObject.arguments[0].companyFollowObject.state,
            }, true);
        }
    }
}
